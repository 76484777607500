import React, { useEffect, useState, useRef } from "react";

import "./UserDashboard.css";
import NavigationBar from "./NavBar/NavigationBar";
import ImgAsset from "./../../../../public";
import CourseList from "../../../Course/CourseCard";
import QualityList from "../../../Quality/QualityCard";
import Footer from "../../../Footer/Footer";
import { useLocation } from "react-router-dom";

const UserDashboard = () => {
	const location = useLocation()
	const [ userData, setUserData ] = useState(null)

	// const userData = location.state?.userData

	const [courses, setCourses] = useState([])

	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [phone, setPhone] = useState("")
	const [apiMessage, setApiMessage] = useState("")

    const [selectedTime, setSelectedTime] = useState("");
	const [selectedCourse, setSelectedCourse] = useState("");
	const [selectedDate, setSelectedDate] = useState("");

	const [loading, setLoading] =useState(true)
	const [error, setError] = useState(null)

	const bookingFormRef = useRef(null)

	const timeSlots = [
        "10am - 11am", "11am - 12pm", "2pm - 3pm", "3pm - 4pm", 
        "4pm - 5pm", "5pm - 6pm", "6pm - 7pm", "7pm - 8pm", "8pm - 9pm"
    ];

	const [contactInfor, setContactInfo] = useState({
		whatsappNumber: '',
		supportNumber: '',
		supportEmail: ''
	})

	useEffect(() => {
		const storedUserData = localStorage.getItem('userData')
		setUserData(JSON.parse(storedUserData))

		const fetchCourses = async () => {
			setLoading(true)
			try{
				const response = await fetch("https://wlc-erp.frappe.cloud/api/method/wlc_lms.wlc_lms.apis.init.guest_init")

				if (!response.ok) {
					throw new Error(`HTTP error! status: ${response.status}`)
				}

				const data = await response.json()

				if (data.messages && data.messages.course_list && Array.isArray(data.messages.course_list)) {
					setCourses(data.messages.course_list)
					setContactInfo({
						whatsappNumber: data.messages.whatsapp_number,
						supportNumber: data.messages.support_number,
						supportEmail: data.messages.support_email
					})
				} else {
					console.error("COurse List not found:", data)
					setError("Course list not found in the response")
				}
			} catch (error) {
				console.error("Error fetching courses: ", error)
				setError("Error fetching courses. Please try again later.")
			} finally {
				setLoading(false)
			}
		}

		fetchCourses()
	}, [])


	const scrollToBookingForm = () => {
		if (bookingFormRef.current) {
			bookingFormRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}

	const handleWhatsAppClick = () => {
		const phoneNumber = contactInfor.whatsappNumber;
		const message = 'Hello, I would like to book a demo';
		const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`

		window.open(whatsappUrl, '_blank')
	}

	const handleSubmit = async (event) => {
		event.preventDefault();

		try{
			const response = await fetch("https://wlc-erp.frappe.cloud/api/method/wlc_lms.wlc_lms.apis.demo.demo.create_demo_doc", {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					name,
					email,
					phone,
					time_slot: selectedTime,
					selected_date: selectedDate,
					course: selectedCourse,
				})
			})

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`)
			}

			const data = await response.json()
			console.log("data: ", data)

			if (data.messages && data.messages.message) {
				setApiMessage(data.messages.message)
				alert(data.messages.message)

				setName("")
				setEmail("")
				setPhone("")
				setSelectedTime("")
				setSelectedDate("")
				setSelectedCourse("")
			}
		} catch (error) {
			console.error("Error submitting form: ", error)
			setApiMessage("Error submitting form. Please try again later.")
			alert("Error submitting form. Please try again later.")
		}
	}
  	return (
    	<div className='home'>
      		<NavigationBar />

			<section className='Hero'>
				<div className='hero-left'>
					<span className='hero-title'>WILFREEDA'S LANGUAGE CENTRE</span>
					<span className='moto'>We <span>Care,</span> We <span>Transform.</span><br/>Unlock your potential with our proven strategies</span>
					<div className='hero-button' onClick={scrollToBookingForm}>
						<span className='Joinforfree'>CLICK HERE TO BOOK FREE DEMO</span>
					</div>
				</div>
				<img className='hero-img' src = {ImgAsset.Screen1_Image2} alt='heroimg'/>
			</section>


			{/* Carousel */}

			

			{/* <Carousels /> */}


		
		
			{/* Course */}
			
			<section className='Course'>
				<span className='our-course'>OUR PROGRAMES</span>
				<div className='course-list'>
					<CourseList />
				</div>	
			</section>


			{/* Why US */}

			<section className='WhyWLC'>
				<span className='ourprograms_3'>WHY WILFREEDAS LANGUAGE CENTRE?</span>
				<div className='quality-list'>
					<QualityList />
				</div>
			</section>


			{/* About us */}

			<section className='ABOUT'>
				<div className='wrapper'>
					<div className='bgrect'/>
					<div className='inner-wrapper'>
						<span className='about-text'>Established in 2021 as an MSME organization, Wilfreeda's Language Centre (WLC) has been dedicated to delivering online language education ever since. Based in Trivandrum, Kerala, WLC offers a comprehensive online learning platform for foreign languages, language examination preparations, business communication, and various corporate and professional training courses. Leveraging our state-of-the-art Learning Management System, we ensure our students receive the best facilities and cutting-edge solutions for their educational needs.</span>
						<img className='image1' src = {ImgAsset.Screen1_image1} alt='img1'/>
					</div>
				</div>
				<span className='title'>ABOUT US</span>
			</section>
		
		
			{/* Contact */}
			<section className='Contact'>
				<span className='title'>CONTACT US</span>
				<div className='container'>
					<span className='tag'>Better yet, see us in person!</span>
					<span className='text'>If you have questions about the testing programs available to you, feel free to send us a message. We will get back to you as soon as possible.</span>
					<button className='get-call' onClick={handleWhatsAppClick}><img src= {ImgAsset.whatsapp} alt='whatsapp' className='wa-icon'/> <span>Message us on WhatsApp</span></button>
					<span className='name'>Wilfreeda's Language Centre</span>
					<span className='email'>{contactInfor.supportEmail}</span>
					<span className='phone'>{contactInfor.supportNumber}</span>
					<span className='hours'>Hours: 9:00 AM - 9:00 PM All Day</span>
				</div>
			</section>

			<div className="booking-form" ref={bookingFormRef}>
				<h1>Book Free Demo</h1>
				<form className="book-form" onSubmit={handleSubmit}>
					<input type="text" placeholder="Name" value={name} onChange={e => setName(e.target.value)} required/>
					<input type="text" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} required/>
					<input type="text" placeholder="Phone" value={phone} onChange={e => setPhone(e.target.value)} required/>
					<input type='date' placeholder='selected date' value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} required/>
					<label htmlFor="time-slot">Preferred Time Slot:</label>
					<select className="time-select" id="time-slot" value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)} required>
						<option value="">Select a time slot</option>
						{timeSlots.map((slot) => (
							<option key={slot} value={slot}>{slot}</option>
						))}
					</select>
					<select className="time-select" id="course-select" value={selectedCourse} onChange={(e) => setSelectedCourse(e.target.value)} required>
						<option value="">Select a Course</option>
						{courses.map((course) => (
							<option key={course} value={course}>{course}</option>
						))}
					</select>
					
					<button className="popup-button" type="submit">Submit</button>
				</form>
			</div>
		

			{/* Footer */}
			<section className='Footer'>
				<Footer />
			</section>



		</div>
  );
};

export default UserDashboard;
